import { createSelector } from 'reselect';
import _ from 'lodash';

import { RootState } from '..';

export const selectSettings = (state: RootState) => {
  return state.settings;
};

export const selectLang = (state: RootState) => {
  return state.settings.lang;
};

export const selectGeneralSettings = (state: RootState) => {
  return state.settings.general;
};

export const selectTexts = createSelector(selectSettings, selectLang, (settings, lang) => {
  return settings.texts[lang];
});

export const selectGeneralTexts = createSelector(selectTexts, (texts) => {
  return _.get(texts, 'general', {});
});

export const selectFormTexts = createSelector(selectTexts, (texts) => {
  return _.get(texts, 'form', {});
});

export const selectSeoTexts = createSelector(selectTexts, (texts) => {
  return _.get(texts, 'seo', {});
});

export const selectPopUpTexts = createSelector(selectTexts, (texts) => {
  return _.get(texts, 'popUp', {});
});

export const selectTopSectionTexts = createSelector(selectTexts, (texts) => {
  return _.get(texts, 'topSection', {});
});

export const selectErrorsTexts = createSelector(selectTexts, (texts) => {
  return _.get(texts, 'errors', {});
});

export const selectFooterTexts = createSelector(selectTexts, (texts) => {
  return _.get(texts, 'footer', {});
});
