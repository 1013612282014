import * as yup from 'yup';

import { Lead } from 'models/index';

import { endpoint, EndpointError } from '../endpoint';
import { ValidationShape, ERROR as VALIDATION_ERROR } from '../validation';

export type CreateParams = Omit<Lead.Model, 'id'> & {
  id?: Lead.Model['id'];
};
export type CreateResult = { id: Lead.Model['id'] };
export type CreateError = EndpointError;

export const updateParamsSchema = yup.object().shape({
  id: yup.string(),
  fullName: yup
    .string()
    .required(VALIDATION_ERROR.REQUIRED)
    .test('', VALIDATION_ERROR.INVALID_VALUE, (value, context) => {
      const valArr = value?.trim().split(' ');
      if (valArr && valArr.length >= 2) return true;
      return false;
    }),
  email: yup.string().email(VALIDATION_ERROR.INVALID_VALUE).required(VALIDATION_ERROR.REQUIRED),
  phone: yup
    .string()
    .min(9, VALIDATION_ERROR.INVALID_VALUE)
    .max(10, VALIDATION_ERROR.INVALID_VALUE)
    .required(VALIDATION_ERROR.REQUIRED),
  locationOfStay: yup.string().required(VALIDATION_ERROR.REQUIRED),
  description: yup.string().required(VALIDATION_ERROR.REQUIRED),
  confirmation: yup.boolean().oneOf([true], VALIDATION_ERROR.REQUIRED),
} as ValidationShape<CreateParams>);

export const update = endpoint<CreateParams, CreateResult, CreateError>({
  method: 'POST',
  url: () => `/lead`,
});
