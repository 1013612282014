import * as React from 'react';

import { useResponsive, useTranslation } from 'client/hooks';
import { share } from 'utils/share';
import { detectIE } from 'utils/detect-ie';
import { IS_DEV, SHARE_CONSTANTS, CAN_USE_DOM, CONFIG } from 'constants/index';
import { dataLayerEvents } from 'client/utils/gtag';

import logo1 from 'client/assets/header/logo1.svg';
import logo2 from 'client/assets/header/logo2.png';

import wt from 'client/assets/header/wt.svg';
import fb from 'client/assets/header/face.svg';
import ng from 'client/assets/header/ng.svg';

import css from './Header.module.scss';

interface Props {
  className?: string;
}

const Header: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const [isDesktop] = useResponsive('DESKTOP');
  const { translate: seoTexts } = useTranslation('seo');
  const dataLayerInstance = new dataLayerEvents();

  const accessablityBtn = () => {
    if (!CAN_USE_DOM) {
      return null;
    }

    return document.querySelector('#INDmenu-btn');
  };

  const onButtonClick = () => {
    const btn = accessablityBtn();
    if (btn) {
      const eventClick = new Event('click');
      btn.dispatchEvent(eventClick);
    }
  };

  const onMouseEnter = () => {
    let eventFocus;
    const btn = accessablityBtn();
    if (!CAN_USE_DOM) {
      return;
    }

    if (isDesktop) {
      if (detectIE() > 0) {
        eventFocus = document.createEvent('Event');
        eventFocus.initEvent('focus', false, true);
      } else {
        eventFocus = new Event('focus');
      }

      if (btn) {
        btn.dispatchEvent(eventFocus);
      }
    }
  };

  const onMouseLeave = () => {
    let eventBlur;
    const btn = accessablityBtn();

    if (!CAN_USE_DOM) {
      return;
    }

    if (detectIE() > 0) {
      eventBlur = document.createEvent('Event');
      eventBlur.initEvent('blur', false, true);
    } else {
      eventBlur = new Event('blur');
    }

    if (btn) {
      btn.dispatchEvent(eventBlur);
    }
  };

  const onShare = (e: React.SyntheticEvent<HTMLButtonElement>): void => {
    const type = `${e.currentTarget.dataset.id}`;
    const seo = {
      title: seoTexts('title'),
      description: seoTexts('description'),
    };

    if (!IS_DEV) {
      share(type, isDesktop, seo);
    }

    dataLayerInstance.onShareClick(type, CONFIG.domainUrl);
  };

  return (
    <header>
      <div className={css.headerWrapper}>
        <div className={css.logosWrapper}>
          <a href="https://www.ynet.co.il/" target="_blank" rel="noreferrer">
            <img src={logo1} alt="logo" />
          </a>
          <a href="https://www.telma-cereals.co.il/" target="_blank" rel="noreferrer">
            <img src={logo2} alt="logo" />
          </a>
        </div>
        <div className={css.socialls}>
          <button className={css.socialButton} onClick={onShare} data-id={SHARE_CONSTANTS.WHATSAPP}>
            <img src={wt} alt="whatsapp icon" />
          </button>
          <button className={css.socialButton} onClick={onShare} data-id={SHARE_CONSTANTS.FACEBOOK}>
            <img src={fb} alt="facebook icon" />
          </button>
          <button
            className={css.socialButton}
            onClick={onButtonClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <img src={ng} alt="ng icon" />
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
