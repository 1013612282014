import React from 'react';
import { useTranslation } from 'client/hooks';

import css from './Footer.module.scss';

interface Props {
  className?: string;
}

const Footer: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const { translate } = useTranslation('footer');

  return (
    <footer>
      <div className={css.footerWrapper}>
        <a href="https://www.ynet.co.il/article/bkswwa3sn" target="_blank" rel="noreferrer">
          {translate('firstLink')}
        </a>
        <span></span>
        <a href="https://www.ynet.co.il/article/h1oypwysn" target="_blank" rel="noreferrer">
          {translate('secondLink')}
        </a>
      </div>
    </footer>
  );
};

export default Footer;
