import React from 'react';
import _ from 'lodash';

import {
  selectGeneralTexts,
  selectTexts,
  selectFormTexts,
  selectSeoTexts,
  selectPopUpTexts,
  selectTopSectionTexts,
  selectErrorsTexts,
  selectFooterTexts,
} from 'routes/client/redux/settings/selectors';
import { useAppSelector } from './redux';

type TranslationSource = 'general' | 'form' | 'seo' | 'popUp' | 'topSection' | 'errors' | 'footer';

/**
 * Usage:
 * const { translate } = useTranslation('logIn'); // will use store.settings.texts.{lang}.logIn
 * // assuming: store.settings.texts.{lang}.logIn.hello = 'hello ${name}';
 * const str = translate('hello', { name: 'Dear User' });
 * // -> str = 'hello Dear User
 *
 * If source is not provided translate function will use store.setting.texts.{lang} as base
 */
export const useTranslation = (source?: TranslationSource) => {
  const selectorsMap = {
    general: selectGeneralTexts,
    form: selectFormTexts,
    seo: selectSeoTexts,
    popUp: selectPopUpTexts,
    topSection: selectTopSectionTexts,
    errors: selectErrorsTexts,
    footer: selectFooterTexts,
  };
  const texts = useAppSelector(source ? selectorsMap[source] : selectTexts);
  const translate = React.useCallback(
    (path: string, options?: Record<string, string>) => {
      let result = _.get(texts, path, path);
      if (options) {
        const compiled = _.template(result);
        result = compiled(options);
      }

      return result;
    },
    [texts],
  );

  return { translate };
};
