import { CONFIG } from 'constants/index';
import { ApiResponse } from 'endpoints/endpoint';
import { renameFile } from 'utils/index';
import { lead } from 'endpoints/index';

import api from '..';

export const leadApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createLead: builder.mutation<ApiResponse<lead.CreateResult, lead.CreateError>, lead.CreateParams>({
      queryFn: (params) => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();

          if (params.fullName) formData.append('fullName', params.fullName);
          if (params.email) formData.append('email', params.email);
          if (params.phone) formData.append('phone', params.phone);
          if (params.locationOfStay) formData.append('locationOfStay', params.locationOfStay);
          if (params.description) formData.append('description', params.description);

          if (typeof params.confirmation !== 'undefined')
            formData.append('confirmation', params.confirmation.toString());

          const request = new XMLHttpRequest();
          const { method, url } = lead.update(params).args;

          request.onload = () => {
            try {
              const response = JSON.parse(request.response);
              resolve({ data: response as ApiResponse<lead.CreateResult, lead.CreateError> });
            } catch (e) {
              reject(e);
            }
          };
          request.open(method, `${CONFIG.api}${url}`);
          request.onerror = () => {
            reject('Network error');
          };
          request.send(formData);
        });
      },
    }),
  }),
  overrideExisting: false,
});
