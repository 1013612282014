import { CAN_USE_DOM, IS_DEV } from 'constants/index';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

type eventsKeysT = 'event' | 'Category' | 'Action' | 'Label';

type eventsResultT = Record<eventsKeysT, string>;

type eventsParamsT = eventsResultT;

if (CAN_USE_DOM) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    Dcpath: 'telma-kolkore2024',
  });
}

export class dataLayerEvents {
  private eventWrapFunction = ({ Action, Label, Category, event }: eventsParamsT) => {
    const resultObject: eventsResultT = {
      event,
      Category,
      Action,
      Label,
    };

    return !IS_DEV && window.dataLayer.push(resultObject);
    // return window.dataLayer.push(resultObject);
  };

  public onShareClick = (action: string, location: string): void => {
    const params: eventsParamsT = {
      event: 'share_events',
      Action: action,
      Label: location,
      Category: 'Form',
    };

    this.eventWrapFunction(params);
  };

  public onFormSent = (payload: string): void => {
    const params: eventsParamsT = {
      Action: `Form sent`,
      Label: payload,
      Category: 'Form',
      event: 'form_events',
    };

    this.eventWrapFunction(params);
  };
}
