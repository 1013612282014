import React from 'react';
import classNames from 'classnames';

import { useResponsive, useTranslation } from 'client/hooks';

import logo from 'client/assets/top/logo.png';
import css from './TopSection.module.scss';

const TopSection: React.FC = () => {
  const [isMobile] = useResponsive('MOBILE');
  const { translate } = useTranslation('topSection');

  return (
    <section className={css.topSection}>
      <div className={css.info}>
        <div className={css.top}>
          <div className={css.logoWrapper}>
            <img src={logo} alt="logo" />
          </div>
          <div className={css.text}>
            <h1 className={css.title} dangerouslySetInnerHTML={{ __html: translate('title') }}></h1>
            <h2 className={css.subTitle} dangerouslySetInnerHTML={{ __html: translate('subTitle') }}></h2>
          </div>
        </div>

        <p
          className={classNames(css.paragraphFirst, css.paragraph1)}
          dangerouslySetInnerHTML={{ __html: translate('paragraph1') }}
        ></p>
        <p
          className={classNames(css.paragraphSecond, css.paragraph2)}
          dangerouslySetInnerHTML={{ __html: translate('paragraph2') }}
        ></p>
      </div>
    </section>
  );
};

export default TopSection;
